import React, { useEffect } from "react"
import Layout from "./layout"
import SEO from "./seo"
import "react-id-swiper/lib/styles/css/swiper.css";
import $ from "jquery"
import { Container, Row, Col } from 'react-bootstrap';
import AniLink from "gatsby-plugin-transition-link/AniLink"

const SingleArticle = ({ pageContext }) => {
    useEffect(() => {
        // Article Video
        setTimeout(function(){
            if ($(".article-videoLink").length > 0) {
                $('.article-videoLink').click(function () {
                    var _videow = $(".embed-responsive").width();
                    var _videoh = $(".embed-responsive").height();
                    var video = '<div class="embed-responsive embed-responsive-16by9"><iframe id="modal-video" src="' + $(this).attr('data-video') + '" height="' + _videoh + '" width="' + _videow + '" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowfullscreen></iframe></div>';
                    $(this).parent('.embed-responsive').replaceWith(video);
                    return false;
                });
            }
        },500);
    }, []);

    const articleGallery = pageContext.gallery.map((gallery_item, index) => {
        return <div className="gallery_list_item" key={index}>
            {gallery_item.article_gallery_item_type === "image" && <Row>
                <Col className="gallery_list_item_title_sec" lg={{span: 4}}>
                    <h6>{gallery_item.article_gallery_item_title}</h6>
                    <p>Photo caption: {gallery_item.article_gallery_item_caption}</p>
                </Col>
                <Col lg={{span: 8}}>
                    <div className="text-center">
                        <img src={gallery_item.article_gallery_item_image} alt={gallery_item.article_gallery_item_title} />
                    </div>
                </Col>
            </Row>} 
            {gallery_item.article_gallery_item_type === "video" && <Row>
                <Col className="gallery_list_item_title_sec" lg={{span: 4}}>
                    <h6>{gallery_item.article_gallery_item_title}</h6>
                    <p>Video caption: {gallery_item.article_gallery_item_caption}</p>
                </Col>
                <Col lg={{span: 8}}>
                    {/* <img src={gallery_item.article_video_poster} alt={gallery_item.article_gallery_item_title} /> */}
                    <div className="article_gallery_video_link_img">
                        <div className="embed-responsive embed-responsive-16by9">
                            <img className="img-fluid" src={gallery_item.article_video_poster} alt={gallery_item.article_gallery_item_title} />
                            <a href="#!" className="article-videoLink" data-video={gallery_item.article_gallery_video_link}><span>PLAY VIDEO</span></a> 
                        </div>
                    </div>
                </Col>
            </Row>}
            {gallery_item.article_gallery_item_type === "doc" && <Row>
                <Col className="gallery_list_item_title_sec" lg={{span: 4}}>
                    <h6>{gallery_item.article_gallery_item_title}</h6>
                    <p>Document caption: {gallery_item.article_gallery_item_caption}</p>
                </Col>
                <Col lg={{span: 8}}>
                    <div className="article-docLink_wrap">
                        <img src={gallery_item.article_gallery_item_image} alt={gallery_item.article_gallery_item_title} />
                        <a href={gallery_item.article_gallery_item_doc} target="_blank" className="article-docLink"><span>DOWNLOAD PDF</span></a>
                    </div>
                </Col>
            </Row>}
        </div>
    });

    return (
        <Layout>
            <SEO title={pageContext.title} />
            <div className="article_gallery_page">
                <div className="gallery_desc_sec">
                    <Container>
                        <Row>
                            <Col className="order-1 order-sm-0" sm={{span:4}} xl={{span:3}}>
                                <h2 className="gallery_desc_title">GALLERY</h2>
                            </Col>
                            <Col className="text-sm-right order-0 order-sm-1" sm={{span:8}} xl={{span:9}}>
                                {pageContext.slug && <AniLink className="back-to-article-btn" fade duration={2} to={"/" + pageContext.slug}>BACK TO ARTICLE <span className="close-btn">X</span></AniLink>}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{span:10}} xl={{span:9}}>
                                {pageContext.title && <h1 className="page-title">{pageContext.year}: {pageContext.title}</h1>}
                                {pageContext.short_description && <div className="gallery_desc" dangerouslySetInnerHTML={{ __html: pageContext.short_description }} />}
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="gallery_list">
                    <Container>
                        {articleGallery}
                    </Container>
                </div>
            </div>
        </Layout>
    )
}

export default SingleArticle
